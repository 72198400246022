import React from "react";
import {CloseButton,Card,Modal,Row,Col} from 'react-bootstrap';
import InputText from "../../../common/input/InputText";
import IconButton from "../../../common/IconButton";

function IbotTemplateNameUpdateDlg(props) {
    const templateName=()=>{
        if(props.fieldValue)
            return props.fieldValue;
        else return "";
    }

    const pageTitle=()=>{
        if(props.fieldValue)
            return "Update Template Name : " + props.fieldValue;
        else return "New Template Name";
    }

    const save=()=>{

        props.handleSaveClick(true);
    }

    return(
        <Modal show={props.show} onHide={props.handleCloseClick}>
            <Modal.Body>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>{pageTitle()}</Col>
                            <Col className="text-end"><CloseButton onClick={props.handleCloseClick}/></Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <InputText id="templateName" icon="bi-files" placeholder="Template Name" fieldValue={templateName()} lenghtSize="lg"/>
                        <Row><Col className="text-end"><IconButton iconName="bi-floppy" name="Save" handleClick={save}/></Col></Row>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    )
}

export default IbotTemplateNameUpdateDlg;