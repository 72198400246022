import React from "react";
import Header from '../../common/Header';
import {Button,Form,Row, Col, Card,Container} from 'react-bootstrap';
import InputText from '../../common/input/InputText'
import InputDateTimePeriod from "../../common/input/InputDateTimePeriod";
import InputSelect from "../../common/input/InputSelect";
import InputMsgContent from "../../common/input/InputMsgContent";
import PageTitle from "../../common/PageTitle";

function IbotNewCampaign(props) {
    const senderList = [
        {id:1,name:"admin"},
        {id:2,name:"sales"},
        {id:3,name:"info"}
    ];

    const targetGroupList = [
        {id:1,name:"Mid Age Male"},
        {id:2,name:"Mid Age Female"},
        {id:3,name:"Male in Kowloon"}
    ];

    const templateList = [
        {id:1,name:"Spring Promotion"},
        {id:2,name:"Wearing Discount"},
        {id:3,name:"Event Promotion"}
    ];

    const maxDailyContactList = [
        {id:100,name:100},
        {id:200,name:200},
        {id:300,name:300}
    ];    
    return(
        <>
            <Header />
            <Container>
                <Card>
                    <Card.Body>
                        <PageTitle title="iBot New Campaign" icon="bi-chat-text" titleColor="rgba(108, 228, 207, 0.5)"  />
                        <Card>
                            <Card.Body>
                                <Form>
                                    <InputText id="campaignName" icon="bi-person-vcard" placeholder="Campaign Name" fieldValue=""/>
                                    <InputDateTimePeriod id="campaignDate" icon="bi-calendar-date" fieldType="date" fieldStartValue="" fieldEndValue=""/>
                                    <InputDateTimePeriod id="campaignTime" icon="bi-alarm" fieldType="time" fieldStartValue="" fieldEndValue=""/>
                                    <InputSelect id="campaignSendFrom" icon="bi-postage-fill" placeholder="Select the Sender" fieldValue="" fieldData={senderList}/>
                                    <InputSelect id="campaignTargetGroup" icon="bi-bullseye" placeholder="Select the target group" fieldValue="" fieldData={targetGroupList}/>
                                    <InputSelect id="campaignTemplate" icon="bi-files" placeholder="Select the iBot Script Template" fieldValue="" fieldData={templateList}/>
                                    <InputSelect id="campaignMaxDaily" icon="bi-123" placeholder="Select the Maximum Daily Process Contact" fieldValue="" fieldData={maxDailyContactList}/>

                                    <Row className='m-3'><Col><Button variant="primary" size="lg" href='./main'>Save</Button></Col></Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
}

export default IbotNewCampaign;