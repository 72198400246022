import React, { useState } from "react";
import Header from '../common/Header';
import {Container,Card,Row,Col} from 'react-bootstrap';
import PageTitle from "../common/PageTitle";
import { useNavigate } from 'react-router-dom';

import TargetGroupItem from "./TargetGroupItem";
import SimpleSearchBox from "../common/SimpleSearchBox";

function TargetGroup(props) {
    const [targetGroupData, setTargetGroupData] = useState(
        //testing date
        [
            {id:1,name:'Middle Age Male',dimension:[{id:1,name:'Gender',value:'male'},{id:3,name:'Age',value:'20-39'}],total:3456},
            {id:2,name:'North NT Female',dimension:[{id:2,name:'Gender',value:'female'},{id:4,name:'Location',value:'North NT'}],total:14567},
            {id:3,name:'Middle Age Male',dimension:[{id:1,name:'Gender',value:'male'},{id:3,name:'Age',value:'20-39'},{id:3,name:'Age',value:'20-39'}],total:3456},
            {id:4,name:'North NT Female',dimension:[{id:2,name:'Gender',value:'female'},{id:4,name:'Location',value:'North NT'}],total:14567}
        ]
    );

    const navigate = useNavigate();
    const gotoEdit = () => {
        navigate('/targetGroupEdit', {state:{targetGroup:null}});

    };  

    return(
        <>
            <Header />
            <Container>
                <Card>
                    <Card.Body>
                        <PageTitle title="Target Group" icon="bi-bullseye" addNewButton="true" titleColor="rgba(140, 239, 0, 0.5)" handleNewClick={gotoEdit} />
                        <Card className="mb-1">
                            <Card.Body>
                                <Row className='m-1'>
                                    <Col xs={12} sm={6} md={7}>
                                        <SimpleSearchBox 
                                            id="targetGroupSearch" 
                                            name="Target Group" 
                                            iconName="bi-bullseye" 
                                            placeholder="Target Group Name" 
                                            handleClick={()=>{}}/>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>                            
                                <Row>
                                    {
                                        targetGroupData.map((item)=>(
                                            <TargetGroupItem targetGroupItem={item} />
                                        ))
                                    }
                                </Row>

                            </Card.Body>
                        </Card>
                    </Card.Body>
                </Card>

            </Container>

        </>
    )
}

export default TargetGroup;