import React,{useState} from "react";
import {Container,Card,Stack,InputGroup,Form,Row,Col, CardHeader, CardBody} from 'react-bootstrap';

import IconButton from "../../../common/IconButton";
import SimpleSearchBox from "../../../common/SimpleSearchBox";

import InputText from "../../../common/input/InputText";
import { isXMLDoc } from "jquery";

function IbotScriptItem(props) {
            /*{id:1,text:"Hello !",nextScript:2,type:1}*/

    //question selected color
    const scriptBookmarkColor ='#0d6efd';
    const scriptAnswerBookmarkColor =['#d63384','#ffc107','#0dcaf0','#adb5bd','#fd7e14','#20c997','#000','#6f42c1'];

    const getScriptContent=()=>{
        return props.scriptData?.text
    }

    const scriptSelected=()=>{
        if(props.scriptData)
            props.handleSelectedScriptId(props.scriptData?.id);
    }

    const save=()=>{
        props.clickEditScript(props.scriptData?.id);
    }

    return(
        <Card className="mb-1">
            <CardHeader onClick={scriptSelected}>
                <Row>
                    <Col>
                        {
                            (props.isNextDefaultScript)&&(
                                <i class="bi bi-bookmarks-fill" style={({color:scriptBookmarkColor})}/>                               
                            )
                        }
                        {
                            props.answersNextId?.map((next,nextIdx)=>(
                                (next.id===props.scriptData.id)&&(
                                    <i class="bi bi-bookmarks-fill" style={({color:scriptAnswerBookmarkColor[next.pos]})}/>
                                )
                            ))
                        }
                         {getScriptContent()}
                         {
                            (props.selectedScriptId === props.scriptData?.id)&&(props.scriptData)&&(
                                <><i class="bi bi-arrow-right" style={{color:'grey'}}/>
                                <i class="bi bi-bookmark-fill" style={({color:scriptBookmarkColor})}/></>
                            )
                        }
                        {(props.isNew==="true")&&(
                            <>
                                <IconButton 
                                    variant="secondary" className="m-1" 
                                    iconName="bi-plus-lg" name="Add Script"
                                    handleClick={()=>{props.handleScriptEditClick(-1)}}
                                    />
                            </>
                        )}
                    </Col>
                    {(props.selectedScriptId === props.scriptData?.id)&&(
                        <Col className="text-end">
                            {(props.isNew!=="true")&&(
                                <>
                                    <IconButton 
                                        size="sm" variant="secondary" 
                                        className="m-1" iconName="bi-pen" name="Edit"
                                        handleClick={()=>{props.handleScriptEditClick(props.scriptData?.id)}}
                                        />
                                    <IconButton size="sm" variant="secondary" className="m-1" iconName="bi-trash" name="Delete"/>
                                </>
                            )}
                        </Col>
                    )}
                </Row>
            </CardHeader>
            { (props.selectedScriptId === props.scriptData?.id)&&(props.scriptData)&&(
                <CardBody>
                    {
                        props.scriptData?.answers?.map((answer,idx)=>(
                            <Card className="mb-1">
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <i class="bi bi-dot" style={{fontSize:"1.5em", color:'grey'}}/>
                                            <span style={{fontSize:"1.5em", color:'grey'}}> {answer.text}</span>
                                            {(answer.nextScript)&&(
                                                <><i class="bi bi-arrow-right" style={{color:'grey'}}/>
                                                <i class="bi bi-bookmark-fill" style={({color:scriptAnswerBookmarkColor[idx]})}/></>
                                            )}
                                        </Col>
                                        <Col className="text-end">
                                            <IconButton size="sm" variant="secondary" className="m-1" iconName="bi-pen" name="Edit"/>
                                            <IconButton size="sm" variant="secondary" className="m-1" iconName="bi-trash" name="Delete"/>
                                        </Col>
                                    </Row>  
                                </Card.Body>
                            </Card>

                        ))
                    }
                    <Card className="mb-1">
                        <Card.Body>
                        <IconButton 
                            size="sm" variant="secondary" className="m-1" 
                            iconName="bi-plus-lg" name="Add Answer"
                            />
                        </Card.Body>
                    </Card>
                </CardBody>
            )}
        </Card>
    )
}

export default IbotScriptItem;