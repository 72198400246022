import React from "react";
import {CloseButton,Card,Modal,Row,Col} from 'react-bootstrap';
import InputText from "../common/input/InputText";
import IconButton from "../common/IconButton";

function TargetGroupNameUpdateDlg(props) {
    const targetGroupName=()=>{
        if(props.fieldValue)
            return props.fieldValue;
        else return "";
    }

    const pageTitle=()=>{
        if(props.fieldValue)
            return "Update : " + props.fieldValue;
        else return "New Target Group Name";
    }

    const save=()=>{

        props.handleSaveClick(true);
    }

    return(
        <Modal show={props.show} onHide={props.handleCloseClick}>
            <Modal.Body>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>{pageTitle()}</Col>
                            <Col className="text-end"><CloseButton onClick={props.handleCloseClick}/></Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <InputText id="targetGroupName" icon="bi-bullseye" placeholder="Target Group Name" fieldValue={targetGroupName()} lenghtSize="lg"/>
                        <Row><Col className="text-end"><IconButton iconName="bi-floppy" name="Save" handleClick={save}/></Col></Row>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    )
}

export default TargetGroupNameUpdateDlg;