import React from "react";
import {Stack,Button} from 'react-bootstrap';


function IconButton(props) {
    const buttonSize= ()=>{
        if(props.size)
            return props.size;
        else return '';

    }

    const buttonVariant= ()=>{
        if(props.variant)
            return props.variant;
        else return 'primary';

    }

    const buttonWidth=()=>{
        if(props.buttonWidth)
            return props.buttonWidth;
        else return '';
    }

    return(

        <Button className={props.className} size={buttonSize()} variant={buttonVariant()} onClick={props.handleClick} style={{width:buttonWidth()}}>
            <Stack direction="horizontal" gap={1}>
                <i class={"bi "+props.iconName} style={ { fontSize:'1em', color: 'white' } }/>
                <div>{props.name}</div>
            </Stack>
        </Button>         

    )
}

export default IconButton;