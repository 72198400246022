import React from "react";
import {CloseButton,Card,Modal,Row,Col} from 'react-bootstrap';
import IconButton from "../common/IconButton";
import InputSelect from "../common/input/InputSelect";

function TargetGroupDimensionSelectionDlg(props) {
    const targetGroupName=()=>{
        if(props.fieldValue)
            return props.fieldValue;
        else return "";
    }

    const save=()=>{
        props.handleSaveClick(true);
    }

    const dimensionGroup=()=>{
        return [
            {id:1,name:"Age"},
            {id:2,name:"Gender"},
            {id:3,name:"Location"}
        ];
    }

    const dimensionValue=()=>{
        return [
            {id:1,name:"10"},
            {id:2,name:"20"},
            {id:3,name:"30"}
        ];
    }
    return(
        <Modal show={props.show} onHide={props.handleCloseClick}>
            <Modal.Body>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>Dimension</Col>
                            <Col className="text-end"><CloseButton onClick={props.handleCloseClick}/></Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <InputSelect id="dimensionGroup" icon="bi-pie-chart" placeholder="Select the Dimension" fieldValue="" fieldData={dimensionGroup()} lenghtSize="lg"/>
                        <InputSelect id="dimensionValue" icon="bi-diagram-3" placeholder="Select the value" fieldValue="" fieldData={dimensionValue()} lenghtSize="lg"/>
                        
                        <Row><Col className="text-end"><IconButton iconName="bi-floppy" name="Save" handleClick={save}/></Col></Row>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    )
}

export default TargetGroupDimensionSelectionDlg;