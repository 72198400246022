import React ,{useRef}from "react";
import Header from '../common/Header';
import {Container,Card,Row,Col} from 'react-bootstrap';
import PageTitle from "../common/PageTitle";
import 'chart.js/auto';
import { Doughnut, getElementAtEvent,Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

function Dashboard(props) {
    const statusData = {
        labels:["Pending", "Approved","Waiting","Despatching"],
        datasets:[{
            data:[4,2,3,1]
        }]
    }

    const statusChartRef= useRef();
    const options = {
        cutout : '70%',
        hoverOffset: 20,
        borderWidth : 1,
        maintainAspectRatio: true,
        plugins:{
            datalabels:{
                display:true,
                color: '#eee',
                font:{
                    size:24
                },
            },
            responsive: true,
            scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
        }
      };

    const barOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            datalabels:{
                display:false,
            }
        },
    };
      
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
      
    const barData = {
        labels,
        datasets: [
          {
            label: 'SMS',
            data: [12343,23453,25123,23455,23480,16000,34512],
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Email',
            data: [12343,21453,23123,24455,12480,22000,34512],
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
          {
            label: 'WhatsApp',
            data: [12343,23453,25123,23455,23480,32000,54512],
            backgroundColor: 'rgba( 162, 235,53, 0.5)',
          }
        ],
    };

    return(
        <>
            <Header />
            <Container>
                <Card>
                    <Card.Body>
                        <PageTitle title="Dashboard" icon="bi-bar-chart-steps" titleColor="rgba(255, 99, 132, 0.5)"/>
                        <Card>
                            <Card.Body>
                                <Row className="m-2">
                                    <Col xs={12} sm={4} className="m-1">
                                        <Card className="mb-2">
                                            <Card.Header>Active Campaign</Card.Header>
                                            <Doughnut
                                                data={statusData}
                                                options={options}
                                                plugins={ChartDataLabels}
                                                redraw
                                                width= "100%"
                                                height="250px" 
                                                ref={statusChartRef}
                                                onClick={(ev)=>{
                                                    if(getElementAtEvent(statusChartRef.current,ev).length>0){
                                                        console.log(getElementAtEvent(statusChartRef.current,ev)[0].index)
                                                    }
                                                    }}
                                                />
                                        </Card>
                                    </Col>
                                    <Col xs={12} sm={7} className="m-1">
                                        <Card className="mb-2">
                                            <Card.Header>Dispatched Record</Card.Header>
                                            <Bar options={barOptions} data={barData} />
                                        </Card>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}

export default Dashboard;