import React from "react";
import {Card,Stack,Row,Col} from 'react-bootstrap';
import IconButton from "./IconButton";

function PageTitle(props) {

    const titleColor=()=>{
        if(props.titleColor)
            return props.titleColor;
        else return 'grey';
    }

    return(
        <Card className="mb-1">
            <Card.Body>
                <Row>
                    <Col>
                        <Stack direction="horizontal" gap={3}>
                            <i className={"bi "+props.icon} style={{'fontSize' : '3rem',color:titleColor(),WebkitTextStrokeWidth: '1px',WebkitTextStrokecolor:'black'}}></i>
                            <div className="h1" style={{color:titleColor(),WebkitTextStrokeWidth: '1px',WebkitTextStrokecolor:'black'}}>{props.title}</div> 
                            {(props.textEdit==="true")&&(
                                    <IconButton className="m-2" iconName="bi-pen" name="Edit" handleClick={props.handleEditClick}/>
                            )}
                        </Stack>
                    </Col>
                    {props.addNewButton &&(
                        <Col className="text-end">
                            <IconButton size="lg" className="m-2" iconName="bi-plus-lg" name="New" handleClick={props.handleNewClick}/>
                        </Col>
                    )}
                </Row>
        </Card.Body>
        </Card>

    )
}

export default PageTitle;