import React from "react";
import {CloseButton,Card,Modal,Row,Col} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import IconButton from "../common/IconButton";

function NewCampaignDlg(props) {
    //new Campaign Selected handling function
    const navigate = useNavigate();
    const newDirectCampaign = (event) => {
        navigate('/directCampign');

    };    

    const newIbotCampaign = (event) => {
        navigate('/ibotCampign');

    };    


    return(
        <Modal show={props.show} onHide={props.handleCloseClick}>
            <Modal.Body>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>Select New Campagin Type</Col>
                            <Col className="text-end"><CloseButton onClick={props.handleCloseClick}/></Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row className="m-2 text-center">
                            <Col><IconButton variant="secondary" iconName="bi-envelope" name="Direct Campaign" handleClick={newDirectCampaign} buttonWidth="70%"/></Col>
                        </Row>
                        <Row className="m-2 text-center">
                            <Col><IconButton variant="secondary" iconName="bi-chat-text" name="iBot Campaign" handleClick={newIbotCampaign} buttonWidth="70%"/></Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    )
}

export default NewCampaignDlg;