import React,{useState} from "react";
import Header from '../common/Header';
import {Container,Card} from 'react-bootstrap';
import PageTitle from "../common/PageTitle";
import PersonEditText from "./PersonEditText";
import PersonEditDimensionList from "./PersonEditDimensionList";
import PersonEditPermissionEdit from "./PersonEditPermissionEdit";
import PersonEditSelect from "./PersonEditSelect";

function PersonEdit(props) {
    //person date
    const [perosnData, setPersonData] =useState(
        {id:1,firstname:'David',lastname:'Lau',mobile:'85292210000',email:'absv@abc.com',
        dimension:[{id:1,name:'Gender',value:'Male'},{id:2,name:'Age',value:'25-39'}],
        permission:[{id:1,name:'Email',value:true},{id:2,name:'SMS',value:true},{id:3,name:'Whatsapp',value:false}],
        preferMedia:3}
    );

    const selectMedia = [
        {id:1,name:"Email"},
        {id:2,name:"SMS"},
        {id:3,name:"Whatsapp"}
    ];

    return(
        <>
            <Header />
            <Container>
                <Card>
                    <Card.Body>
                        <PageTitle title={"Customer : "+perosnData.firstname +" "+perosnData.lastname} icon="bi-person"  titleColor="rgba(53, 162, 235, 0.5)"/>
                        <Card className="mb-2">
                            <Card.Body>
                                <PersonEditText iconName="bi-phone" fieldName="Mobile" fieldValue={perosnData.mobile}/>
                                <PersonEditText iconName="bi-envelope-at" fieldName="Email" fieldValue={perosnData.email}/>
                                <PersonEditDimensionList dimension={perosnData.dimension}/>
                                <PersonEditPermissionEdit permission={perosnData.permission} />
                                <PersonEditSelect iconName="bi-star" fieldName="Preferred" fieldData={selectMedia} fieldSelected={perosnData.preferMedia}/>
                            </Card.Body>
                        </Card>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}

export default PersonEdit;