import React, { useState } from "react";
import Header from '../common/Header';
import BootstrapTable from 'react-bootstrap-table-next';
import {Container,Card,Col,Row} from 'react-bootstrap';
import { format } from 'date-fns/format';
import PageTitle from "../common/PageTitle";

import NewCampaignDlg from "./NewCampaignDlg";
import CopyCampaignDlg from "./CopyCampaignDlg";
import IconButton from "../common/IconButton";

import SimpleSearchBox from "../common/SimpleSearchBox";

function Campaign(props) {
    //new Campagin dialog state
    const [showNewCamapignDlg, setShowNewCampaignDlg] = useState(false);
    //copy Campagin dialog state
    const [showCopyCamapignDlg, setShowCopyCampaignDlg] = useState(false);
    //Filter : direct type filter state
    const [filterDirectCampaign, setFilterDirectCampaign] = useState(true);
    //Filter : direct type iBot state
    const [filterIbotCampaign, setFilterIbotCampaign] = useState(true);

    //return filter Colour
    const filterChannelTypeColor =(status)=>{
        if (status)
            return "success";
        else return "secondary"
    }

    //testing date
    const data =[
        {name:'New Year',startDate:'2024-1-1',endDate:'2024-1-5',status:'P',channelType:1},
        {name:'Easter',startDate:'2024-4-1',endDate:'2024-4-5',status:'P',channelType:2},
        {name:'New Year 2',startDate:'2024-1-1',endDate:'2024-1-5',status:'D',channelType:1},
        {name:'New Year 3',startDate:'2024-1-1',endDate:'2024-1-5',status:'S',channelType:1},
        {name:'New Year 4',startDate:'2024-1-1',endDate:'2024-1-5',status:'F',channelType:1},
    ] 

    const campaignStatus = {
        "P":{text:"Pending",color:"blue"},
        "D":{text:"Despatching",color:"green"},
        "S":{text:"Suspened",color:"red"},
        "F":{text:"Completed",color:"black"}
    };

    //table column setting
    const columns = [
        {
            dataField: 'name',
            text: 'Campaign Name',
            sort: true ,
            formatter:nameFormatter          
        }, {
            text: 'Date',
            formatter:dtFormatter
        }, {
            dataField: 'status',
            text: 'Status',
            formatter:statusFormatter
        }, {
            formatter:actionFormatter
        }
    ]; 

    //date column formatter, use to change the date format and link up start date and end date
    function actionFormatter(cell, row) {
        return(
            <IconButton size="sm" variant="secondary" iconName="bi-copy" name="Copy" handleClick={()=>{setShowCopyCampaignDlg(true)}}/>
        )
    } 
   
    
    //date column formatter, use to change the date format and link up start date and end date
    function dtFormatter(cell, row) {
        return(
            <div>{format(Date(row.startDate), 'do MMMM yyyy')} - {format(Date(row.endDate), 'do MMMM yyyy')}</div>
        )
    } 


    //name column formatter, use to link up the name and the type
    function nameFormatter(cell, row) {
        let icon ="";

        switch(row.channelType) {
            case 2:
                icon="bi bi-chat-text";
                break;
            default: //case 1
                icon="bi bi-envelope";
          }

        return(
            <div>
                <i class={icon} style={{'font-size' : '1.5rem', color:'black'}}></i>
                <span className="m-2">{cell}</span>
            </div>
        )
    } 

    //status column formatter, use to change the status code and colour
    function statusFormatter(cell, row) {
        return(
            <div>
                <span className="m-2" style={{color:campaignStatus[cell].color}}>{campaignStatus[cell].text}</span>
            </div>
        )
    } 

    return(
        <>
            <Header />
            <NewCampaignDlg show={showNewCamapignDlg} handleCloseClick={()=>(setShowNewCampaignDlg(false))} />
            <CopyCampaignDlg show={showCopyCamapignDlg} handleCloseClick={()=>(setShowCopyCampaignDlg(false))} />

            <Container>
                <Card>
                    <Card.Body>
                        <PageTitle title="Campaign Management" icon="bi-megaphone" addNewButton="true" titleColor="rgba(108, 228, 207, 0.5)" handleNewClick={()=>(setShowNewCampaignDlg(true))} />
                        <Card className="mb-1">
                            <Card.Body>
                                <Row className="m-1">
                                    <Col xs={12} sm={6} md={7}>
                                        <SimpleSearchBox 
                                                    id="campaignNameSearch" 
                                                    name="Campaign" 
                                                    iconName="bi-megaphone" 
                                                    placeholder="Campaign Name" 
                                                    handleClick={()=>{}}/>
                                    </Col>
                                </Row>
                                <Row className="m-1">
                                    <Col xs={4} sm={4} md={3} lg={1}>
                                        <IconButton 
                                            size="sm" 
                                            variant={filterChannelTypeColor(filterDirectCampaign)} 
                                            iconName="bi-envelope" 
                                            name="direct" 
                                            handleClick={()=>{setFilterDirectCampaign(!filterDirectCampaign)}}
                                            buttonWidth="70px"
                                            />
                                    </Col>
                                    <Col xs={4} sm={4} md={3} lg={1}>
                                        <IconButton 
                                            size="sm" 
                                            variant={filterChannelTypeColor(filterIbotCampaign)} 
                                            iconName="bi-chat-text" 
                                            name="iBot" 
                                            buttonWidth="70px"
                                            handleClick={()=>{setFilterIbotCampaign(!filterIbotCampaign)}}
                                            />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>                        
                                <BootstrapTable 
                                    keyField='name' 
                                    data={ data } 
                                    columns={ columns } 
                                    bordered={ false } 
                                    noDataIndication="Table is Empty" 
                                    hover condensed/>
                            </Card.Body>
                        </Card>
                    </Card.Body>
                </Card>

            </Container>

        </>
    )
}

export default Campaign;