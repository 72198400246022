import React from "react";
import {CloseButton,Card,Modal,Row,Col} from 'react-bootstrap';
import IconButton from "../common/IconButton";
import InputText from "../common/input/InputText";
import InputSelect from "../common/input/InputSelect";

function CopyCampaignDlg(props) {
    //new Campaign Selected handling function

    const save = (event) => {
        props.handleCloseClick();
    };    

    const languageList = [
        {id:1,name:"Same"},
        {id:2,name:"English"},
        {id:3,name:"繁體中文"},
        {id:4,name:"簡體中文"}
    ];


    return(
        <Modal show={props.show} onHide={props.handleCloseClick}>
            <Modal.Body>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>Copy Campagin to</Col>
                            <Col className="text-end"><CloseButton onClick={props.handleCloseClick}/></Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <InputText id="campaignName" icon="bi-person-vcard" placeholder="New Campaign Name" fieldValue="" lenghtSize="lg" />
                        <InputSelect id="convertTo" icon="bi-translate" placeholder="Convert to" fieldValue="" fieldData={languageList}  lenghtSize="lg"/>
                        <IconButton variant="secondary" iconName="bi-copy" name="Copy" handleClick={save}/>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    )
}

export default CopyCampaignDlg;