import React,{useState} from "react";
import Header from '../common/Header';
import {Container,Card,Stack,Button,InputGroup,Form,Row,Col,DropdownButton,Dropdown,Alert} from 'react-bootstrap';
import PageTitle from "../common/PageTitle";
import BootstrapTable from 'react-bootstrap-table-next';
import { useNavigate } from 'react-router-dom';

function PersonList(props) {
    const [searchData, setSearchData] = useState([]);
    const [searchType, setSearchType] = useState(1);

    function updateSearchResult(){
        setSearchData(data);
    }

    function clearSearchResult(){
        setSearchData([]);
    }

    function getResultStatus(){
        if(searchData.length>0)
            return true;
        else return false;
    }
    const searchTypeIcon = {
        1:{name:'Customer Name',icon:'bi bi-person-vcard'},
        2:{name:'Mobile',icon:'bi bi-phone'},
        3:{name:'Email',icon:'bi bi-envelope-at'}
    }

    //person date
    const data =[
        {id:1,firstname:'David',lastname:'Lau',mobile:'85292210000',email:'absv@abc.com',dimension:[{id:1,name:'Gender',value:'Male'}],preferMedia:2},
        {id:2,firstname:'David',lastname:'Lau',mobile:'85292210000',email:'absv@abc.com',dimension:[{id:1,name:'Gender',value:'Male'}],preferMedia:1},
        {id:3,firstname:'David',lastname:'Lau',mobile:'85292210000',email:'absv@abc.com',dimension:[{id:1,name:'Gender',value:'Male'}],preferMedia:3},
        {id:4,firstname:'David',lastname:'Lau',mobile:'85292210000',email:'absvdfdsds@abc.com',dimension:[{id:1,name:'Gender',value:'Male'}],preferMedia:2},
        {id:5,firstname:'David',lastname:'Lau',mobile:'85292210000',email:'absv@abc.com',dimension:[{id:1,name:'Gender',value:'Male'}],preferMedia:2},
    ]

    //table column setting
    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true ,
            formatter:nameFormatter          
        }, {
            text: 'mobile',
            formatter:mobileFormatter
        }, {
            text: 'email',
            formatter:emailFormatter
        }, {
            text: 'Preferred Media',
            formatter:mediaFormatter
        }]; 

    //name column formatter, use to link up the name and the type
    function nameFormatter(cell, row) {
        return(
            <div>{row.firstname +" "+ row.lastname}</div>
        )
    } 
    
    function mobileFormatter(cell,row){
        return(
            <div>{row.mobile.substring(0,row.mobile.length-4)+'XXXX'}</div>
        )
    }
        
    function emailFormatter(cell,row){

        return(
            <div>{row.email.substring(0,row.email.indexOf('@'))+'@XXXX'}</div>
        )        
    }

    function mediaFormatter(cell,row){
        let icon ="";
        let mediaName ="";

        switch(row.preferMedia) {
            case 1:
                icon="bi bi-envelope-at";
                mediaName='Email';
                break;
            case 2:
                icon="bi bi-envelope";
                mediaName='SMS';
                break;
            case 3:
                icon="bi bi-whatsapp";
                mediaName='Whatsapp';
                break;
            default:
                icon="";
                mediaName='';
          }

        return(
            <Stack direction="horizontal" gap={1}>
                <i class={icon} style={{'font-size' : '1.5rem', color:'black'}}></i>
                <div>{mediaName}</div>
            </Stack>
        )        
    }

    const navigate = useNavigate();

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            navigate('/personEdit');
        },
    };

    return(
        <>
            <Header />
            <Container>
                <Card>
                    <Card.Body>
                        <PageTitle title="Customer Management" icon="bi-people" titleColor="rgba(53, 162, 235, 0.5)"/>
                        <Card className="mb-2">
                            <Card.Body>
                                <Row className='m-1'>
                                    <Col xs={12} sm={6} md={7}>
                                        <InputGroup>
                                            <InputGroup.Text id="customerName">
                                                <Stack direction="horizontal" gap={1}>
                                                    <i class={searchTypeIcon[searchType].icon}  style={ { fontSize:'1em', color: 'black' } }/>
                                                    <div>{searchTypeIcon[searchType].name}</div>
                                                </Stack>
                                            </InputGroup.Text>
                                            <DropdownButton variant="outline-secondary">
                                                <Dropdown.Item onClick={()=>{setSearchType(1)}}>Name</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>{setSearchType(2)}}>Mobile</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>{setSearchType(3)}}>Email</Dropdown.Item>
                                            </DropdownButton>
                                            <Form.Control
                                                placeholder=""
                                                aria-label=""
                                                aria-describedby=""
                                                /> 
                                            <Button variant="primary" onClick={updateSearchResult}>
                                                <Stack direction="horizontal" gap={1}>
                                                    <i class="bi bi-search" style={ { fontSize:'1em', color: 'white' } }/>
                                                    <div>Search</div>
                                                </Stack>
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Alert variant="dark" show={getResultStatus()}  onClose={clearSearchResult} dismissible>
                            <Alert.Heading>Search Result</Alert.Heading>
                                <BootstrapTable 
                                keyField='id' 
                                data={ searchData } 
                                columns={ columns } 
                                bordered={ false } 
                                rowEvents={ rowEvents }
                                noDataIndication="No Matched Records Found" 
                                hover condensed/>
                        </Alert>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}

export default PersonList;