import React from "react";
import {CloseButton,Card,Modal,Row,Col} from 'react-bootstrap';
import InputText from "../../../common/input/InputText";
import IconButton from "../../../common/IconButton";
import InputSelect from "../../../common/input/InputSelect";
import ImageUploader from 'react-images-upload';

function IbotTemplateScriptUpdateDlg(props) {
    const scriptContent=()=>{
        var retVal="";
        if(props.selectedId!==-1) 
            retVal=props.scripts.find((obj)=> obj.id===props.selectedId)?.text;
        return retVal;
    }
    const pageTitle=()=>{
        if(props.selectedId===-1)
            return "New Script";
        else return "Update Script";
    }
    const nextScriptSepected=()=>{
        var retVal=-1;
        if(props.selectedId!==-1) 
            retVal=props.scripts.find((obj)=> obj.id===props.selectedId)?.nextScript;
        return retVal;
    }


    const nextScriptOption=()=>{
        var retVal = [];
        props.scripts.forEach((obj) => {
            if(obj.id!==props.selectedId)
            retVal.push({id:obj.id,name:obj.text});
        });
        retVal.push({id:-98,name:'[End]'});
        retVal.push({id:-99,name:'[Closing]'});
        return retVal;
    }

    return(
        <Modal show={props.show} backdrop="static">
            <Modal.Body>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>{pageTitle()}</Col>
                            <Col className="text-end"><CloseButton onClick={props.handleCloseClick}/></Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <ImageUploader
                            withIcon={true}
                            buttonText='Choose image'

                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                            maxFileSize={5242880}
                            />
                        <InputText id="templateName" icon="bi-123" placeholder="Script Content" fieldValue={scriptContent()} lenghtSize="lg"/>
                        <InputSelect 
                            id="nextScript" 
                            icon="bi-arrow-right" 
                            lenghtSize="lg" 
                            placeholder="Select the next script" 
                            fieldValue={nextScriptSepected()} 
                            fieldData={nextScriptOption()}/>
                        <Row><Col className="text-end"><IconButton iconName="bi-floppy" name="Save" handleClick={props.handleSaveClick}/></Col></Row>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    )
}

export default IbotTemplateScriptUpdateDlg;