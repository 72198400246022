import React, { useState} from "react";
import Header from '../common/Header';
import {Container,Stack,Card,Row,Col,Badge} from 'react-bootstrap';
import PageTitle from "../common/PageTitle";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import IconButton from "../common/IconButton";

import TargetGroupNameUpdateDlg from "./TargetGroupNameUpdateDlg";
import TargetGroupDimensionSelectionDlg from "./TargetGroupDimensionSelectionDlg";

function TargetGroupEdit(props) {
    const [showTargetGroupNameUpdateDlg, setShowTargetGroupNameUpdateDlg] = useState(false);
    const [showTargetGroupDimensionSelectionDlg, setShowTargetGroupDimensionSelectionDlg] = useState(false);

    const saveTargetGroupName=(result)=>{
        setShowTargetGroupNameUpdateDlg(false);
    }

    const saveTargetGroupDimension=(result)=>{
        setShowTargetGroupDimensionSelectionDlg(false);
    }
   
    let location = useLocation();

    const pageTitle=()=>{
        if(location.state.targetGroupData)
            return location.state.targetGroupData?.name;
        else return 'New Target Group';
    }

    const navigate = useNavigate();
    const gotoCustomerList = (event) => {
        navigate('/targetGroupPersonList');

    };     
    return(
        <>
            <Header />
            <TargetGroupNameUpdateDlg 
                show={showTargetGroupNameUpdateDlg} 
                fieldValue={location.state.targetGroupData?.name} 
                handleCloseClick={()=>{setShowTargetGroupNameUpdateDlg(false)}}
                handleSaveClick={saveTargetGroupName}
                />

            <TargetGroupDimensionSelectionDlg 
                show={showTargetGroupDimensionSelectionDlg} 
                fieldValue={location.state.targetGroupData?.name} 
                handleCloseClick={()=>{setShowTargetGroupDimensionSelectionDlg(false)}}
                handleSaveClick={saveTargetGroupDimension}
                />

            <Container>
                <Card>
                    <Card.Body>
                        <PageTitle title={pageTitle()} icon="bi-bullseye"  textEdit="true" titleColor="rgba(140, 239, 0, 0.5)" handleEditClick={()=>{setShowTargetGroupNameUpdateDlg(true)}}/>
                        <Card>
                            <Card.Body>
                                <Card>
                                    <Card.Header>
                                        <Row>
                                            <Col>
                                                <Stack direction="horizontal" gap={3}>
                                                <div className="h3">Dimension</div>
                                                    <IconButton 
                                                        size="sm"
                                                        iconName="bi-list" name="Customer List" variant="secondary"
                                                        handleClick={()=>{gotoCustomerList()}}/>
                                                </Stack>
                                            </Col>
                                            <Col className="text-end">
                                                <Badge bg="info">{Number(location.state.targetGroupData?.total).toLocaleString()}</Badge>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        {
                                            location.state.targetGroupData?.dimension?.map((obj)=>(
                                                <Row className='m-1'>
                                                    <Col>
                                                        <Stack direction="horizontal" gap={1}>
                                                            <i class="bi bi-people" style={ { fontSize:'1em', color: 'black' } }/>
                                                            <div>
                                                                <span>{obj.name}</span>
                                                            </div>
                                                        </Stack>
                                                    </Col>
                                                    <Col>
                                                        <span style={{color:'blue'}}>{obj.value}</span>
                                                    </Col>
                                                    <Col className="text-end">
                                                        <IconButton className="m-1" size="sm" variant="secondary" iconName="bi-pen" name="Edit" handleClick={()=>{setShowTargetGroupDimensionSelectionDlg(true)}}/>
                                                        <IconButton className="m-1" size="sm" variant="secondary" iconName="bi-trash" name="Delete"/>
                                                    </Col>
                                                    <Col></Col>
                                                </Row>
                                            ))
                                        }
                                        <Row className='m-1'><Col>
                                            <IconButton 
                                                iconName="bi-plus-lg" name="Add" 
                                                handleClick={()=>{setShowTargetGroupDimensionSelectionDlg(true)}}/>
                                        </Col></Row>
                                    </Card.Body>
                                </Card>
                            </Card.Body>
                        </Card>
                    </Card.Body>
                </Card>
            </Container>

        </>
    )
}

export default TargetGroupEdit;